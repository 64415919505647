<template>
	<div class="newsIndex page">
		<div class="newsIndex-top">
			<div class="newsIndex-top-left" v-if="newList[0]"
				:style="`background-image:url(${newList[0]?newList[0].mobile_image:''})`">
				<div class="newsIndex-top-left-content">
					<div class="titTop">
						<p class="title">{{newList[0]?newList[0].title:''}}</p>
						<p>{{newList[0]?newList[0].business_time:''}}</p>
					</div>
					<p class="text" v-html="newList[0]?newList[0].describe:''"></p>
					<div class="btn" @click="$router.push(`/brandStory/news/details/${newList[0].id}`)">
						{{lang==6?'阅读原文':'READ MORE'}}
					</div>
				</div>
			</div>
			<div class="newsIndex-top-right">
				<div class="top" v-if="newList[1]" :style="`background-image:url(${newList[1]?newList[1].image:''})`"
					@click="$router.push(`/brandStory/news/details/${newList[1].id}`)">
					<div>{{newList[1]?newList[1].title:''}}</div>
				</div>
				<!-- <swiper :options="swiperOption" ref="mySwiper">
					<swiper-slide class="swiper-slide" v-for="(item,index) in bannerList" :key="index">
						<img :src="item.image" mode="widthFit" class="swpierImg" />
					</swiper-slide>
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper> -->
				<div class="top bottom" v-if="newList[2]"
					:style="`background-image:url(${newList[2]?newList[2].image:''})`"
					@click="$router.push(`/brandStory/news/details/${newList[2].id}`)">
					<div>{{newList[2]?newList[2].title:''}}</div>
				</div>
			</div>
		</div>
		<div class="newsIndex-bottom">
			<div class="item" v-for="(item,index) in oldList" :key="index">
				<img :src="item.image" />
				<div class="newsIndex-bottom-text">
					<p class="title">{{item.title}}</p>
					<p class="text" v-html="item.describe"></p>
				</div>
				<div class="bottom">
					<p>{{item.business_time}}</p>
					<p class="big" @click="$router.push(`/brandStory/news/details/${item.id}`)">
						{{lang==6?'了解更多':'READ MORE'}} >
					</p>
				</div>
			</div>
			<div class="btn" @click="$router.push(`/newsList`)">{{lang==6?'查看更多新闻':'See more news'}}</div>
		</div>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper/src/index.js";
	import 'swiper/dist/css/swiper.css';
	import mixinLang from '../../../components/mixin.js'
	export default {
		metaInfo: {
			title: '宝爱捷(中国)汽车投资有限公司', // set a title
			meta: [{ // set meta
					name: 'keyWords',
					content: '宝爱捷新闻，宝马，BMW，baoma，奥迪，Audi，aodi，宾利，binli，bentley，兰博基尼，lamborghini，牛头，保时捷，porsche，mini，豪车'
				},
				{
					name: 'description',
					content: ''
				}
			]
		},
		name: 'newsIndex',
		mixins: [mixinLang],
		components: {
			swiper,
			swiperSlide
		},
		props: {},
		data() {
			return {
				// 轮播配置
				swiperOption: {
					slidesPerView: 1,
					loop: true,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false
					},
					// 显示分页
					pagination: {
						el: ".swiper-pagination",
						clickable: true //允许分页点击跳转
					}
				},
				newList: [{}, {}],
				oldList: [],
				bannerList: []
			}
		},
		methods: {
			getInfo() {
				this.$api.getArticleList({
					cate_no: 'b7567587',
					form: 'all'
				}, {
					page: 1,
					limit: 7
				}).then(res => {
					this.newList = res.list.splice(0, 3)
					this.oldList = res.list
				})
				// 轮播
				// this.$api.getBannerList({
				// 	cate_no: 'fset34v34'
				// }).then(res=>{
				// 	this.bannerList = res.banner_list
				// })
			}
		}
	}
</script>

<style lang="scss" scoped>
	.newsIndex-top {
		display: flex;
		color: #FFF;
		padding: 87px;
		justify-content: space-between;

		.newsIndex-top-left {
			width: 840px;
			height: 880px;
			background-image: url(../../../assets/brandStory/news/bg.png);
			background-size: cover;
			position: relative;

			&:hover .newsIndex-top-left-content {
				display: block;
			}

			.newsIndex-top-left-content {
				padding: 60px 50px;
				width: 660px;
				height: 700px;
				background: rgba(151, 35, 63, 0.56);
				backdrop-filter: blur(15px);
				position: absolute;
				top: calc(50% - (700px/2));
				left: calc(50% - (660px/2));
				display: none;

				.titTop {
					font-size: 18px;
					color: #FFF;

					p {
						font-family: SourceHanSansCN-Normal, sans-serif;
						font-size: 18px;
						font-weight: 350;
					}

					.title {
						font-size: 32px;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
						font-weight: bold;
					}
				}

				/deep/.text {
					font-size: 20px;
					line-height: 40px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
					color: #FFF !important;
					height: 115px;
					overflow: hidden;
				}

				.btn {
					width: 320px;
					height: 72px;
					border: 4px solid #FFFFFF;
					text-align: center;
					position: absolute;
					bottom: 54px;
					left: calc(50% - (320px/2));
					line-height: 62px;
					font-size: 32px;
					font-weight: 500;
					color: #FFF;
					cursor: pointer;
					text-transform: uppercase;
				}
			}
		}

		.newsIndex-top-right {
			width: 840px;

			.top {
				height: 460px;
				background-size: cover;
				margin-bottom: calc(880px - 460px - 400px);
				font-family: SourceHanSansCN-Medium, sans-serif;
				font-size: 32px;
				font-weight: 500;
				cursor: pointer;

				>div {
					height: 436px;
					padding: 51px 61px;
					background: linear-gradient(180deg, #000000 0%, #000000 27%, rgba(0, 0, 0, 0.00) 100%, rgba(0, 0, 0, 0.00) 100%);
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					display: none;
				}

				&:hover>div {
					display: block;
				}
			}

			.bottom {
				height: 400px;
			}

			p {
				width: 100%;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			.swpierImg {
				width: 100%;
				height: 400px;
			}

			/deep/ .swiper-pagination-bullet-active {
				background-color: #97233F;
			}
		}
	}

	.newsIndex-bottom {
		display: flex;
		padding: 50px 87px 250px;
		justify-content: space-between;
		position: relative;

		.item {
			width: 400px;
			height: 560px;
			background: #FFFFFF;
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
			position: relative;
			display: inline-block;
			// margin: 0 30px;
			// margin-right: 80px;

			&:nth-child(3) {
				margin-right: 0;
			}

			img {
				width: 100%;
				height: 252px;
			}

			.newsIndex-bottom-text {
				padding: 0 23px;

				.title {
					font-family: SourceHanSansCN-Bold, sans-serif;
					font-size: 26px;
					font-weight: bold;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin: 30px 0;
				}

				.text {
					font-family: SourceHanSansCN-Normal, sans-serif;
					font-size: 18px;
					font-weight: 350;
					line-height: 36px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
			}

			.bottom {
				position: absolute;
				bottom: 31px;
				padding: 0 23px;
				display: flex;
				justify-content: space-between;
				color: #999999;
				font-family: SourceHanSansCN-Normal, sans-serif;
				font-size: 18px;
				font-weight: 350;
				width: 100%;
				align-items: center;

				.big {
					font-family: SourceHanSansCN-Bold, sans-serif;
					font-size: 22px;
					font-weight: bold;
					color: #333333;
					cursor: pointer;
				}
			}

		}

		.btn {
			position: absolute;
			width: 304px;
			height: 77px;
			box-sizing: border-box;
			border: 4px solid #97233F;
			font-family: SourceHanSansCN-Bold, sans-serif;
			font-size: 18px;
			font-weight: bold;
			color: #97233F;
			line-height: 67px;
			text-align: center;
			bottom: 51px;
			left: calc(50% - 152px);
			cursor: pointer;
			text-transform: uppercase;
		}
	}
</style>